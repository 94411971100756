/**
 * JAVASCRIPT
 * Name: Navigation
 ********************************* */

$(function() {
  'use strict';


  /**
   * VARIABLES
   *********************** */

  var $win = $(window),
      $doc = $(document),
      $body = $('body');

  var currentMousePos = { x: -1, y: -1 };


  /**
   * INIT
   *********************** */
  $doc.ready(function() {
    if(!$body.hasClass('.nav-active')) {
      $('.js-navigation-link').attr('tabindex', '-1');
    }
  });



  /**
   * GET MOUSE POSITION
   *********************** */
  $doc.mousemove(function(event) {
    currentMousePos.x = event.pageX;
    currentMousePos.y = event.pageY - $win.scrollTop();
  });


  /**
   * ON HOVER
   *********************** */
  $('.js-navigation-link').hover(function() {
    var $picture = $(this).next('.js-navigation-picture');
    $picture.addClass('is-visible');
  }, function() {
    var $picture = $(this).next('.js-navigation-picture');
    $picture.removeClass('is-visible');
  });


  /**
   * ON MOUSE MOVE
   *********************** */
  $('.js-navigation-link').on('mousemove', function() {
    var $picture = $(this).next('.js-navigation-picture');
    $picture.css('top', currentMousePos.y);
    $picture.css('left', currentMousePos.x);
  });

});

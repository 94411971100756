/**
 * JAVASCRIPT
 * Name: Header
 ********************************* */

$(function() {
  'use strict';


  /**
   * VARIABLES
   *********************** */

  var $win = $(window),
      $body = $('body');

  var lastScrollTop = $win.scrollTop();


  /**
   * FUNCTIONS
   *********************** */
  function setScrollClass(scrollTop) {

    if(scrollTop != 0) {
      $body.addClass('is-scrolled');
    }
    else {
      $body.removeClass('is-scrolled');
    }
  }


  /**
   * ON SCROLL
   *********************** */
  $win.on('scroll', function(event) {
    setScrollClass($win.scrollTop());
  });


  /**
   * ON CLICK
   *********************** */
  $('.js-header-nav').on('click', function(event) {
    event.preventDefault();

    if(!$body.hasClass('nav-active')) {
      lastScrollTop = $win.scrollTop();
      $body.addClass('nav-active');
      $('.js-navigation-link').attr('tabindex', '0');
    }
    else {
      $body.removeClass('nav-active');
      $win.scrollTop(lastScrollTop);
      $('.js-navigation-link').attr('tabindex', '-1');
    }
  });


  /**
   * INIT
   *********************** */
  setScrollClass($win.scrollTop());

});

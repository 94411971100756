import svg4everybody from 'svg4everybody';
import 'focus-visible/dist/focus-visible'; // Auto-executing, see : https://github.com/WICG/focus-visible
import AOS from 'aos';

var $doc = $(document);

svg4everybody();

import './front/_browser'
import './front/_header'
import './front/_langs'
import './front/_navigation'
import './front/_slider'
// import './front/_popin'

$(window).on('load', function() {
  setTimeout(function(){
    AOS.init({
      duration: 1000,
      easing: 'ease',
      delay: 0,
    });
  }, 300);
});

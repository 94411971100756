/**
 * JAVASCRIPT
 * Name: Slider
 ********************************* */

import $ from 'jquery';
import 'slick-carousel';

$(function() {
  'use strict';


  /**
   * BODY
   *********************** */
  $('.js-slider-body').slick({
    asNavFor: '.js-slider-foot',
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    adaptiveHeight: false,
    prevArrow: '.js-slider-prev',
    nextArrow: '.js-slider-next',
  });


  /**
   * FOOT
   *********************** */
  $('.js-slider-foot').slick({
    asNavFor: '.js-slider-body',
    arrows: false,
    dots: true,
    dotsClass: 'c-slider__dots',
    speed: 1000,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
  });


  /**
   * PLAY/PAUSE
   *********************** */
  $('.js-slider-runner').on('click', function(event) {
    var $slider = $('.js-slider');

    $slider.toggleClass('on-pause');

    if($slider.hasClass('on-pause')) {
      $('.js-slider-body').slick('slickPause');
    }
    else {
      $('.js-slider-body').slick('slickPlay');
    }
  });

});

/**
 * JAVASCRIPT
 * Name: Langs
 ********************************* */

$(function() {
  'use strict';


  /**
   * VARIABLES
   *********************** */
  var $toggle = $('.js-langs-toggle');


  /**
   * ACTION
   *********************** */
  $toggle.on('click', function(event) {
    event.preventDefault();

    $(this).parents('.js-langs').toggleClass('is-opened');
  });

});
